import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20231101-Rate-Sheet.pdf";
const date = "01.11.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Wednesday 1 November 2023 we will be making the following changes to our range:</p>
<p><strong>Summary of changes</strong></p>
<p>We have reintroduced our 2 Year Fixed Fee Saver offering for UK Residential First Time Buyer and Home Mover customers at 95% LTV. These products have been reintroduced with a cashback incentive of &pound;250 until further notice.</p>
<p><strong>UK Residential Existing Customer Switching Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Standard at 60%, 70%, 75% and 90% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 85% and 90% LTV have decreased</li>
<li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
<li>10 Year Fixed Standard at 70%, 75% and 80% LTV have decreased</li>
<li>2 Year Tracker Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
</ul>
<p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Standard at 60%, 70%, 75% and 90% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 85% and 90% LTV have decreased</li>
<li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
<li>10 Year Fixed Standard at 70%, 75% and 80% LTV have decreased</li>
</ul>
<p><strong>UK Residential First Time Buyer Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
</ul>
<p><strong>UK Residential Home Mover Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
</ul>
<p><strong>UK Residential Remortgage Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>2 Year Fixed Standard at 70%, 75%, 80% and 85% LTV have decreased</li>
<li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>3 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 90% LTV have decreased</li>
<li>5 Year Premier Exclusive at 60%, 70%, 75%, 80% and 90% LTV have decreased</li>
</ul>
<p><strong>UK Residential Remortgage Cashback Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
<li>2 Year Fixed Standard at 70%, 75%, 80% and 85% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 70%, 75% and 90% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 90% LTV have decreased</li>
</ul>
<p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Tracker Standard at 60% and 65% LTV has decreased</li>
</ul>
<p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Tracker Standard at 60% and 65% LTV has decreased</li>
</ul>
<p><strong>UK Buy to Let Purchase Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Tracker Standard at 60% and 65% LTV have decreased</li>
</ul>
<p><strong>UK Buy to Let Remortgage Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 65% and 75% LTV have decreased</li>
<li>2 Year Tracker Standard at 60% LTV has decreased</li>
</ul>
<p><strong>International Residential Range</strong></p>
<ul>
<li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
<li>2 Year Fixed Standard at 70% and 75% LTV have decreased</li>
<li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
<li>3 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
<li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
<li>5 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
<li>5 Year Premier Exclusive at 60%, 70% and 75% LTV have decreased</li>
<li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have decreased</li>
<li>10 Year Fixed Standard at 60%, 70% and 75% LTV have decreased</li>
</ul>
<p>There are no changes to any other interest rates at this time.</p>
<p>An updated mortgage rate sheet will be published on Wednesday 1 November 2023 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
